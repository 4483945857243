import React from "react";
import "./foredragItem.scss"

import Img1 from "../../images/putin_flyer.jpg"
import Img2 from "../../images/dannebrogs_aere_flyer.jpg"
import Img3 from "../../images/kold_krig_og_gas_flyer.jpg"
import Img4 from "../../images/prisen_for_soenderjylland.jpg"
import Img5 from "../../images/stay_behind_flyer.jpg"
import Img6 from "../../images/flugten_oestersoeen_flyer.jpg"
import Img7 from "../../images/faestningen_flyer.jpg"
import Img8 from "../../images/james_bond_den_danske_forbindelse.jpg"
import Img9 from "../../images/oekologiske_jernbaner.jpg"
import Img10 from "../../images/privatebanen_lyngby_vedbaek.jpg"
import Img11 from "../../images/oekologiske_jernbaner.jpg"
import Img12 from "../../images/det_saarbare_samfund.jpg"
import Img13 from "../../images/gensyn_med_axel_mathiesen.jpg"

import Flyer1 from "../../images/putin_flyer.pdf"
import Flyer2 from "../../images/dannebrogs_aere_flyer.pdf"
import Flyer3 from "../../images/kold_krig_og_gas_flyer.pdf"
import Flyer4 from "../../images/prisen_for_soenderjylland.pdf"
import Flyer5 from "../../images/stay_behind_flyer.pdf"
import Flyer6 from "../../images/flugten_oestersoeen_flyer.pdf"
import Flyer7 from "../../images/faestningen_flyer.pdf"
import Flyer8 from "../../images/james_bond_den_danske_forbindelse.pdf"
import Flyer9 from "../../images/oekologiske_jernbaner.pdf"
import Flyer10 from "../../images/privatebanen_lyngby_vedbaek.pdf"
import Flyer11 from "../../images/oekologiske_jernbaner.pdf"
import Flyer12 from "../../images/det_saarbare_samfund.pdf"
import Flyer13 from "../../images/gensyn_med_axel_mathiesen.pdf"

const Images:string[] = [
  Img8, Img7, Img6, Img5, Img4, Img1, Img2, Img3, Img9, Img10, Img11, Img12, Img13
]

const Flyer:string[] = [
  Flyer8, Flyer7, Flyer6, Flyer5 , Flyer4, Flyer1, Flyer2, Flyer3, Flyer9, Flyer10, Flyer11, Flyer12, Flyer13
]

interface ForedragItemProps {
  id: number
  title: string
  text: any
  synopsis?: any
  other?: any
  image?: string
  long?: boolean
}

const ForedragItem = ({id, title, text, synopsis, other, image, long}: ForedragItemProps) => {
  const stringId = `${id}`
  return (
    <section className="foredrag" id={stringId}>
      <div className="foredrag__content">
        <div className="foredrag__text">
          <h2>{title}</h2>
          <div dangerouslySetInnerHTML={{__html: text}}/>
          <div className="foredrag__other" dangerouslySetInnerHTML={{__html: other}}/>
        </div>
        
        <div className="foredrag__image">
          <a href={Flyer[id]} rel="noopener noreferrer" target="_blank">
            <img src={Images[id]} className={Images[id]} alt={`'${title}'. Forfatter: Niels Jensen`}/>
          </a>
        </div>
        
      </div>

    </section>
  )
}

export default ForedragItem;